import styles from './index.css';
import actionButton from './actionButton';
import { poweredBy } from '../../../../components';
import headline from './embedHeadline';
import communityIntro from './communityIntro';
import contentItem from './contentItem';
import Settings from '../../../../helpers/settings';
import {
  initializeFeedbackButtonsListeners,
} from './contentItem/feedbackButtons';

const mentionsList = async function (mentionsQuery, embed, paginationFn) {
  const mentions = mentionsQuery.getDataInMemory();
  const { locale = 'en' } = Settings.getAll();

  const showPagination = mentionsQuery.hasMorePages()
    || (!mentionsQuery.hasMorePages() && mentions.length > 1);

  const { contentEmbedShowHeadline } = embed;

  initializeFeedbackButtonsListeners();

  return `
    <div>
      <div class="${styles.guuruMentionContainer}">
        <div style="width: 100%;">
          ${contentEmbedShowHeadline ? `<div class="${styles.guuruHeadline}">${headline(mentionsQuery.getCount())}</div>` : ''}
          <div id="guuru-mention-loading"></div>
          <div class="${styles.mentionsWrapper}">
            <div class="${styles.mentionsContainer}">
              <div id="mentions" class="${styles.mentions}">
                ${mentions?.map((mention) => contentItem({ locale, ...mention.node }).innerHTML).join('')}
              </div>
              <div class="${styles.footer}">
                ${actionButton(!embed.contentEmbedShowBanner)}
                ${showPagination ? paginationFn(mentionsQuery) : ''}
              </div>
              ${poweredBy('guuru-content-embed-powered-by')}
            </div>
            ${await communityIntro(embed, '180px', true)}
          </div>
        </div>
      </div>
    </div>
  `;
};

export default mentionsList;
