import styles from './index.css';
import { heroes } from '../../../../../components';
import { renderFeedbackButtons } from './feedbackButtons';

const contentItem = function ({
  locale = 'en',
  author: {
    name,
    bio,
    photoUrl,
  } = {},
  id,
  summary,
  createdAt,
  headline,
}) {
  const html = `
    <div>
      <div class="${styles.expertTeaserContent}">
        ${heroes({ avatars: [photoUrl], width: 60 })}
        <div>
          <span>${name}</span>
          <span>${bio || ''}</span>
        </div>
      </div>
      <div class="${styles.contentBody}">
        ${headline ? `<div class="${styles.headline}">${headline}</div>` : ''}
        <span>"${summary}"</span>
        <div class="${styles.contentFooter}">
          <p>${createdAt}</p>
          ${renderFeedbackButtons({ locale, id })}
        </div>
      </div>
    </div>
  `;
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  return wrapper;
};

export default contentItem;
